import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
            Version 1.0.0 © Copyright 2020, The Nielsen Consumer LLC. Never guess. All Rights Reserved. Privacy Policy. Terms of Use.
        </div>
  )
}

export default Footer